import React, { useState } from "react";
import "../styles/project.css";

function Project(props) {
  const { image, title, description, githubLink } = props;
  const [hover, setHover] = useState(false);

  return (
    <div
      className="project"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="project-image-container">
        <img className="project-img" src={image} alt="Project" />
      </div>
      <div className="project-title-container">
        <div className="text-container">
          <p>{description}</p>
        </div>
      </div>
      <div className={`project-overlay ${hover ? "show" : ""}`}>
        <a
          href={githubLink}
          target="_blank"
          rel="noopener noreferrer"
          className="project-button"
        >
          {title}
        </a>
      </div>
    </div>
  );
}

export default Project;
