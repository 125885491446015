import React from "react";
import "../styles/contacts.css";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import GitHubIcon from "@mui/icons-material/GitHub"; // Import GitHub icon

function Contacts() {
  return (
    <div className="main-contacts-container">
      <header>
        <h1 className="header">Contact.</h1>
      </header>

      <main>
        <div className="contact-item">
          <EmailIcon className="contact-icon" fontSize="large" />
          <a href="mailto:romang2103@gmail.com" className="contact-info">
            romang2103@gmail.com
          </a>
        </div>

        <div className="contact-item">
          <LinkedInIcon className="contact-icon" fontSize="large" />
          <a
            href="https://www.linkedin.com/in/roman-gurung"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-info"
          >
            linkedin.com/in/roman-gurung
          </a>
        </div>

        <div className="contact-item">
          <GitHubIcon className="contact-icon" fontSize="large" />
          <a
            href="https://github.com/romang2103"
            target="_blank"
            rel="noopener noreferrer"
            className="contact-info"
          >
            github.com/romang2103
          </a>
        </div>

        <div className="contact-item">
          <PhoneIcon className="contact-icon" fontSize="large" />
          <a href="tel:+447737746211" className="contact-info">
            +44 77377 46211
          </a>
        </div>
      </main>
    </div>
  );
}

export default Contacts;
