import React from "react";
import "../styles/cv.css";

function Cv() {
  // Corrected path to your CV PDF file
  const cvPdfPath = "/RomanGurung.pdf";

  return (
    <div className="main-cv-container">
      <header>
        <h1 className="header">CV.</h1>
      </header>

      <main>
        <iframe
          src={`${cvPdfPath}#navpanes=0`}
          title="My CV"
          width="100%"
          height="100%"
        >
          <a href={cvPdfPath} download className="download-button">
            Download CV
          </a>
        </iframe>
      </main>
    </div>
  );
}

export default Cv;
