import React from "react";
import "../styles/projects.css";
import Project from "../components/project";
import projectImage1 from "../assets/projects/sortingAlgoImage.png";
import projectImage2 from "../assets/projects/eecsConnect.png";
import projectImage3 from "../assets/projects/pocketSiteManager.png";

function Projects() {
  // Define an array of project data
  const projects = [
    {
      image: projectImage1,
      title: "Sorting Algorithm Visualiser",
      description:
        "In my quest to expand my technical skillset, I developed a Sorting Algorithm Visualizer utilizing React. This project was a significant milestone in my coding journey, allowing me to not only learn to code in React but also reinforce my understanding of sorting algorithms. By bringing sorting algorithms to life through visualization, I bridged the theoretical with the practical, enhancing my software development expertise in a hands-on manner.",
      githubLink: "https://github.com/romang2103/sorting-algorith-visualiser",
    },
    {
      image: projectImage2,
      title: "EECS Connect",
      description:
        "As part of a group project in my sophomore year, our team was tasked with developing a comprehensive website for our university's student body. The goal was to create an intuitive online platform that simplifies the process of reporting campus-related issues and monitoring Exceptional Circumstances (ECs) submissions. The website was built using the MERN stack along with a user-friendly navigation to ensure students could effortlessly report concerns and track their ECs status, fostering a more responsive and supportive educational environment.",
      githubLink: "https://github.com/romang2103/qmul-eecs-connect",
    },
    {
      image: projectImage3,
      title: "Pocket Site Manager",
      description:
        "I developed Pocket Site Manager to meet the unique needs of my uncle's work. Frustrated with the limitations of existing software, he required a more functional solution. This app was built using FlutterFlow alongside a Firebase backend. Pocket Site Manager streamlined project management, enhanced operational efficiency, and integrated key features that standard applications failed to offer.",
      githubLink: "https://github.com/romang2103",
    },
  ];

  return (
    <div className="main-projects-container">
      <header>
        <h1 className="header">Projects.</h1>
      </header>

      <main>
        {projects.map((project, index) => (
          <Project
            key={index}
            image={project.image}
            title={project.title}
            description={project.description}
            githubLink={project.githubLink}
          />
        ))}
      </main>
    </div>
  );
}

export default Projects;
