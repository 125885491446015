import React from "react";
import "./App.css";
import Homepage from "./pages/homepage";
import Projects from "./pages/projects";
import Cv from "./pages/cv";
import Contacts from "./pages/contacts";

function App() {
  return (
    <div className="App">
      <section className="section-homepage">
        <Homepage />
      </section>
      <section className="section-projects">
        <Projects />
      </section>
      <section className="section-cv">
        <Cv />
      </section>
      <section className="section-contacts">
        <Contacts />
      </section>
    </div>
  );
}

export default App;
